import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"

const NotFoundPage = () => {
  const { t, i18n } = useTranslation("404")
  const language = i18n.language

  return (
    <Layout>
      <Seo title={"404: " + t("notFound")} lang={language} />
      <Container>
        <Row>
          <Col className="pt-5 pb-5">
            <h1>{t("notFound")}</h1>
            <p>{t("notFoundMessage")}</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
